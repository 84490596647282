
<div id="main">
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
    <div class="container">
        <div style="margin-bottom:35px; margin-left:-10%;text-align:center;background:#404040;width:100%; position:fixed; top:0; z-index:50">
            <!--<img src="https://4.bp.blogspot.com/-7OHSFmygfYQ/VtLSb1xe8kI/AAAAAAAABjI/FxaRp5xW2JQ/s320/logo.png" style="width: 100px;margin-bottom:15px">-->
            &nbsp;
            <h1 id="title">FAScores.com</h1>
        </div>
        <nav>
            <div class="nav-fostrap">
                <ul>
                    <li class="{{(StateID == '11') ? 'highlight' : '' }}">
                        <a href="javascript:void(0);" (click)="navigateTo('11', '1', $event)">Home</a>
                    </li>
                    <li class="{{(StateID == '1') ? 'highlight' : '' }}">
                        <a href="javascript:void(0);" (click)="navigateTo('1', '1', $event)">Overview<span class="arrow-down"></span></a>
                        <ul class="dropdown">
                            <li><a href="javascript:void(0);" (click)="navigateTo('1', '1', $event)">Freedom Score</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('1', '2', $event)">Political Leaning</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('1', '3', $event)">Transparency</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('1', '4', $event)">Objectivity</a></li>
                        </ul>
                    </li>
                    <li class="{{(StateID == '2') ? 'highlight' : '' }}">
                        <a href="javascript:void(0);" (click)="navigateTo('2', '1', $event)">Understanding the Freedom Badge<span class="arrow-down"></span></a>
                        <ul class="dropdown">
                            <li><a href="javascript:void(0);" (click)="navigateTo('2', '1', $event)">Colors</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('2', '2', $event)">Shapes</a></li>
                        </ul>
                    </li>
                    <li class="{{(StateID == '10') ? 'highlight' : '' }}">
                        <a href="javascript:void(0);" (click)="navigateTo('10', '1', $event)">About</a>
                        <ul class="dropdown">
                            <li><a href="javascript:void(0);" (click)="navigateTo('10', '1', $event)">Privacy Policy</a></li>
                            <li><a href="javascript:void(0);" (click)="navigateTo('10', '2', $event)">How Your Votes Affect the Freedom Score</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="nav-bg-fostrap">
                <div class="navbar-fostrap"> <span></span> <span></span> <span></span> </div>
                <a href="" class="title-mobile">FAScores.com</a>
            </div>
        </nav>
        <!--<div class="top-bar" id="content-wrapper">
            <ng-container *ngIf="StateID == '8'">
                <div>
                    <h4 class="header-style"><span style="font-weight:700">Home</span></h4>
                </div>
            </ng-container>
            <ng-container *ngIf="StateID == '9'">
                <div>
                    <h4 class="header-style"><span style="font-weight:700">About</span></h4>
                </div>
            </ng-container>
            <ng-container *ngIf="StateID != '8' && StateID != '9'">
                <div>
                    <h4 class="header-style"><span style="font-weight:700">State:</span> {{State}}; <span style="font-weight:700"> Category: </span>{{Category}}</h4>
                </div>
            </ng-container>
        </div>-->
        <div id="content-wrapper" style="">
            <ng-container *ngIf="StateID == '11'">
                <div class="top-bar2">
                    <h4 class="header-style"><span style="font-weight:700">Home</span></h4>
                </div>
            </ng-container>
            <ng-container *ngIf="StateID == '10'">
                <div class="top-bar2">
                    <h4 class="header-style"><span style="font-weight:700">About</span></h4>
                </div>
            </ng-container>
            <ng-container *ngIf="StateID != '11' && StateID != '10'">
                <div class="top-bar2">
                    <h4 class="header-style"><span style="font-weight:700">State:</span> {{State}}; <span style="font-weight:700"> Category: </span>{{Category}}</h4>
                </div>
            </ng-container>


            <ng-container *ngFor="let data of DynamicPageInfoData; let i = index;">
                <ng-container *ngIf="data.PageID == 11">
                    <div style="padding:8px;font-size:15px;font-weight:bold" [innerHTML]="data.Paragraph1 | safeHtml">
                    </div>
                    <div class="col-sm-8" style="display:inline-block">
                        Last Update: <span style="color:#2ab5ea;font-size:12px;">{{data.LastUpdate | date:'short':'EST'}}</span>
                    </div>
                    <div style="border:1px #e8e8e8 solid;margin:0px 0px 10px 0px">
                        <a href="javascript:void(0);" (click)="expandTabHome(i, $event)">
                            &nbsp;<span class="fa fa-window-maximize"></span>
                        </a>
                        <a href="javascript:void(0);" (click)="minimizeTabHome(i, $event)">
                            &nbsp;<span class="fa fa-window-minimize"></span>
                        </a>
                    </div>
                    <div #incidentDivHome style="padding:8px;font-size:14px;max-height:370px;overflow:auto; " [innerHTML]="data.Paragraph2 | safeHtml">
                    </div>
                </ng-container>
                <ng-container *ngIf="data.PageID == 10">
                    <div style="padding:8px;font-size:17px;" [innerHTML]="data.Paragraph1 | safeHtml">
                    </div>
                    <div #incidentDivAbout style="padding:8px;font-size:14px;max-height:370px;overflow:auto; " [innerHTML]="data.Paragraph2 | safeHtml">
                    </div>
                </ng-container>
                <ng-container *ngIf="data.PageID != 8 && data.PageID != 9">
                </ng-container>
            </ng-container>

        </div>
        </div>
    </div>
<!--<script src="https://ajax.googleapis.com/ajax/libs/jquery/2.2.0/jquery.min.js"></script>-->

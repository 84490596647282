'use strict';

import * as Interfaces from '../interfaces/models.interfaces';

export class DynamicPageInfo implements Interfaces.DynamicPageInfo {

    RecordID: number;
    PageID: number;
    SectionID: number;
    Paragraph1: string;
    Paragraph2: string;
    Paragraph3: string;
    LastUpdate: Date;
}

export class Params {
    pageID: string;
    sectionID: string;
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';

/*
 * Components that the router needs to be aware of should be listed below.
 */
import { HomeComponent } from '../home/home.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
//import { FetchDataComponent } from '../fetch-data/fetch-data.component';


const applicationRoutes: Routes = [
        { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
        { path: 'dashbaord', component: HomeComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
      //{ path: 'counter', component: CounterComponent },
      //{ path: 'fetch-data', component: FetchDataComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(applicationRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {

}

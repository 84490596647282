import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
//import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { SafeHtmlPipe } from './home/home.component';
//import { CounterComponent } from './counter/counter.component';
//import { FetchDataComponent } from './fetch-data/fetch-data.component';

/* Application Routes */
import { AppRoutingModule } from './routing/routing.module'

/* Applicable Service */
import { FraudIncidentDataService } from './services/fraud_incident.service'

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    SafeHtmlPipe,
    HomeComponent
    //CounterComponent,
    //FetchDataComponent
    //Modals declarations go here as well
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    //RouterModule.forRoot([
    //  { path: '', component: HomeComponent, pathMatch: 'full' },
    //  { path: 'counter', component: CounterComponent },
    //  { path: 'fetch-data', component: FetchDataComponent },
    //])


    // or declare an AppRoutingModule component. It must be declared last
    AppRoutingModule /* Add me last */
  ],
  entryComponents: [
    // Declarations of modal components here as well
  ],
    providers: [
        SafeHtmlPipe,
        FraudIncidentDataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

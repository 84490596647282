import { Component, Input, OnInit, OnChanges, ElementRef, ViewChild, ViewChildren, SimpleChange, SimpleChanges, OnDestroy, QueryList, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

//import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

//import {ActiveToast, IndividualToastrConfig, ToastrService } from 'ngx-toaster';

import { FraudIncidentDataService } from '../services/fraud_incident.service';
import  *  as Classes from '../classes/models.classes'

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}


@Component({
  selector: 'privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ["./privacy-policy.component.css"]
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy, OnChanges{


  @Input() Mode: string;
  @Input() StateID: string;
  @Input() CategoryID: string;

    DynamicPageInfoData: Array<Classes.DynamicPageInfo>;

    FirstTabChecked: boolean;
    SecondTabChecked: boolean;
    ThirdTabChecked: boolean;
    FourthTabChecked: boolean;

    Category: string;
    State: string;
    //@ViewChild('tab1Checkbox') tab1Checkbox: ElementRef;
    //@ViewChild('tab2') tab2Checkbox;
    //@ViewChild('tab3') tab3Checkbox;
    //@ViewChild('tab5') tab4Checkbox;

    @ViewChild('ulContent') ulElementView: QueryList<ElementRef>;

    @ViewChildren('ulContent') ulChildrenComponent: QueryList<ElementRef>; 
    @ViewChildren('liContent') liChildrenComponent: QueryList<ElementRef>;

    @ViewChildren('incidentDiv') incidentDivComponent: QueryList<ElementRef>;
    @ViewChildren('incidentDivHome') incidentDivHomeComponent: QueryList<ElementRef>;
    @ViewChildren('incidentDivAbout') incidentDivAboutComponent: QueryList<ElementRef>;

    ulContentHeight: number;

  constructor(
    private fraudIncidentDataService: FraudIncidentDataService,
      private _route: ActivatedRoute,
    private _router: Router
  ) {
  }

  ngOnInit() {
    debugger;
      this.DynamicPageInfoData = new Array<Classes.DynamicPageInfo>();


      this.FirstTabChecked = false;
      this.SecondTabChecked = false;
      this.ThirdTabChecked = false;
      this.FourthTabChecked = false;


    }

    ngAfterViewInit() {

        this._route.queryParams.subscribe(params => {
            this.StateID = params['StateID'];
            this.CategoryID = params['CategoryID'];
            if (this.StateID != undefined && this.StateID != null && this.StateID != ''
                && this.CategoryID != undefined && this.CategoryID != null && this.CategoryID != '') {
            }
            else {
                this.StateID = '8';
                this.CategoryID = '1';
            }
        });




    }

      ngOnDestroy() {

      }
      ngOnChanges() {

        }

}

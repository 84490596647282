import { Injectable } from '@angular/core';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, ReplaySubject, forkJoin, throwError } from 'rxjs';

import { DynamicPageInfoDataUrls } from '../global.urls';
import * as Classes from '../classes/models.classes';

//import { Http } from '@angular/http';

@Injectable()
export class FraudIncidentDataService {
  lcl_params: Classes.Params;

  constructor(
    private http: HttpClient) {

  }

    loadDynamicPageInfoData(PageID: string, SectionID: string) {
        let requestHeaders: HttpHeaders = new HttpHeaders();
        requestHeaders = requestHeaders.append('Content-Type', 'application/json');

        let params = new HttpParams();

        params.append("PageID", PageID.toString());
        params.append("SectionID", SectionID.toString());

        this.lcl_params = new Classes.Params();

        this.lcl_params.pageID = PageID;
        this.lcl_params.sectionID = SectionID;

        let getDynamicPageInfoDataRequest: HttpRequest<Classes.Params> = new HttpRequest<Classes.Params>('POST', DynamicPageInfoDataUrls.Get_DynamicPageData, this.lcl_params, {
            //let getFraudIncidentDataRequest: HttpRequest<Classes.VoterFraudIncident> = new HttpRequest<Classes.VoterFraudIncident>('POST', VoterFraudDataUrls.Get_Incidents, VoterFraudIncidentData, {
            //let getFraudIncidentDataRequest = new HttpRequest('GET', VoterFraudDataUrls.Get_Incidents, '', {
            reportProgress: true,
            headers: requestHeaders
            //params: params
        });

        return this.http.request<Array<Classes.DynamicPageInfo>>(getDynamicPageInfoDataRequest);

    }


}

<body>
    <!--<app-nav-menu></app-nav-menu>-->
    <div class="container">
        <app-home></app-home>
    </div>
    <router-outlet></router-outlet>
    <!--<script src="~/js/jquery.min.js"></script>
    <script src="~/js/popper.js"></script>
    <script src="~/js/bootstrap.min.js"></script>
    <script src="~/js/main.js"></script>-->
</body>
